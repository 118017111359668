/*
 * @Author: your name
 * @Date: 2021-10-18 10:34:53
 * @LastEditTime: 2021-12-02 15:07:28
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \lightning-web\src\api\callCenter.js
 */
import axios from "@/config/httpConfig";
// 保存呼叫中心设置
export function saveCCSetting(data) {
  return axios.post("callCenter/saveCCSetting", data);
}
// 获取呼叫中心设置
export function getCallCenterInfo(data) {
  return axios.post("callCenter/getCallCenterInfo", data);
}
// 查询坐席下拉列表
export function getClientList(data) {
  return axios.post("customerSet/getClientList", data);
}
// 查询绑定列表
export function getBindList(data) {
  return axios.post("customerSet/getBindList", data);
}
// 用户绑定坐席电话
export function bindClientUser(data) {
  return axios.post("customerSet/bindClientUser", data);
}
// 编辑绑定信息
export function updateClientUser(data) {
  return axios.post("customerSet/updateClientUser", data);
}
// 删除绑定信息
export function deleteClientUser(data) {
  return axios.post("customerSet/deleteClientUser", data);
}
// 批量绑定坐席
export function bindClientUsers(data) {
  return axios.post("customerSet/bindClientUsers", data);
}
// 同步绑定天润系统的电话号码
export function bindTel(data) {
  return axios.post("customerSet/bindTel", data);
}
// 发起绑定电话号码验证
export function launchBindTelCheck(data) {
  return axios.post("customerSet/launchBindTelCheck", data);
}
// 确认绑定电话号码验证
export function bindTelCheck(data) {
  return axios.post("customerSet/bindTelCheck", data);
}
// 根据坐席号或者用户查询绑定信息
export function getBindByCnoOrUserId(data) {
  return axios.post("customerSet/getBindByCnoOrUserId", data);
}
// 获取Amazon区域列表
export function getAwsRegions(data) {
  return axios.post("/callCenter/getAwsRegions", data);
}
// 天润查询通话录音记录
export function getCdr(data) {
  return axios.post("/customerSet/getCdr", data);
}
// 天润保存登入密码
export function saveTianRunLogin(data) {
  return axios.post("/customerSet/saveTianRunLogin", data);
}
// 查询手机号码归属地
export function getPhoneCity(data) {
  return axios.post("/customerSet/getPhoneCity", data);
}
// 获取amazon实例列表
export function getInstanceAlias(data) {
  return axios.post("callCenter/listInstance", data);
}
// 保存amzon实例名
export function saveInstance(data) {
  return axios.post("/callCenter/saveInstance", data);
}

// 呼叫中心访客创建规则
export function creatVisitor(data) {
  return axios.post("/callCenter/creatVisitor", data);
}
// 根据电话号码识别联系人与客户
export function IdentifyPhonenumber(data) {
  return axios.post("/callCenter/IdentifyPhonenumber", data);
}
// 呼叫中心保存个案
export function saveCase(data) {
  return axios.post("/callCenter/saveCase", data);
}
// 根据语音记录查询语音记录id
export function getVoiceByVoicerecording(data) {
  return axios.post("/customerSet/getVoiceByVoicerecording", data);
}
// 更新语音转接内容
export function updateTransfer(data) {
  return axios.post("/customerSet/updateTransfer", data);
}
// 获取aws语音录音
export function getConnectCallRecording(data) {
  return axios.post("/callCenter/getConnectCallRecording", data);
}
// 查询试用坐席号
export function getOnTrial(data) {
  return axios.post("/customerSet/getOnTrial", data);
}
// 天润结束免费试用
export function updateCnoActive(data) {
  return axios.post("/callCenter/updateCnoActive", data);
}
// 天润试用发送邮件
export function sendTianRunEmail(data) {
  return axios.post("/customerSet/sendTianRunEmail", data);
}