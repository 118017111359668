<template>
  <div class="transfer-client-owner">
    <el-dialog
      :visible="accesskeyDialog"
      top="15%"
      width="30%"
      :close-on-click-modal="false"
      :before-close="closeloginDialog"
    >
      <div slot="title" class="header-title">
        <div style="display: flex">
          <svg
            class="logoIcon"
            aria-hidden="true"
            slot="reference"
            style="width: 24px; height: 24px; margin-right: 8px"
          >
            <use href="#icon-awsIcon"></use>
          </svg>
          <div>AWS</div>
        </div>
      </div>
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        class="demo-dynamic"
        :rules="rules"
        label-position="left"
      >
      <!-- 访问秘钥ID -->
        <el-form-item prop="accesskey">
          <label slot="label">{{ $t('label.callcenter.access.keyID') }}：</label>
          <!-- 请输入秘钥ID -->
          <el-input
            v-model="ruleForm.accesskey"
            type="password"
            :placeholder="$t('label.callcenter.pleaseenter.access.keyID')"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item prop="privateaccesskey">
          <label slot="label">{{ $t('label.callcenter.private.key') }}： </label>
          <el-input
            v-model="ruleForm.privateaccesskey"
            type="password"
            :placeholder="$t('label.callcenter.pleaseenter.private.key')"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item prop="area" >
          <!-- 区域 -->
          <label slot="label">{{ $t('setup.layout.component.area') }}：</label>
          <el-select v-model="ruleForm.area" :placeholder="$t('label.tabpage.pleaseSelectz')" size="mini">
            <el-option
              v-for="item in areaList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <span>
          <el-button @click="closeloginDialog()" size="mini">
            <!-- 取消 -->
            {{ $t("label.cancel") }}
          </el-button>
          <el-button size="mini" @click="submitForm('ruleForm')" type="primary">
            <!-- 登录   -->
            {{ $t("label.emailsync.form.signin") }}
          </el-button></span
        >
      </span>
    </el-dialog>
  </div>
</template>
<script type="text/ecmascript-6">
import * as callCenter from "./api.js";
export default {
  props: {
    accesskeyDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ruleForm: {
        accesskey: "",
        privateaccesskey: "",
        area: "",
      },
      rules: {
        accesskey: [
          // 请输入秘钥ID
          { required: true, message: this.$i18n.t('label.callcenter.pleaseenter.access.keyID'), trigger: "blur" },
        ],
        // 请输入私有访问秘钥
        privateaccesskey: [
          { required: true, message: this.$i18n.t('label.callcenter.pleaseenter.private.key'), trigger: "blur" },
        ],
        area: [
          { required: true, message: this.$i18n.t('front-kefu-select-region'), trigger: "blur" },    //请选择实例所在区域
        ],
      },
      areaList: [],
    };
  },
  methods: {
    submitForm(formName) {
      let params = {
        appId: this.ruleForm.accesskey,
        appSecret: this.ruleForm.privateaccesskey,
        intergrateType: "aws",
        isEnable: "1",
        callMissed: "2",
        callConnected: "1",
        callUserName: "",
        callPwd: "",
        ishidden: "0",
        isOnTrial: "0",
        region: this.ruleForm.area.substring(
          this.ruleForm.area.lastIndexOf(" ") + 1
        ),
      };
      this.$refs[formName].validate((valid) => {
        if (valid) {
          callCenter
            .saveCCSetting(params)
            .then((res) => {
              if (res.returnCode === "200") {
                // 已成功登录账号！
                this.$message.success(this.$i18n.t('label.callcenter.successfully.logged.account'));
                this.$emit("accesskeySubmit");
                this.$refs[formName].resetFields();
              } else if (res.returnCode === "300") {
                // 数据填写有误
                this.$message({
                  message: this.$i18n.t("label.callcenter.Incorrect.data.filling"),
                  type: "error",
                });
              }
            })
            .catch(() => {});
        } else {
          
          return false;
        }
      });
    },
    closeloginDialog() {
      // this.ruleForm.accesskey = ""
      // this.ruleForm.privateaccesskey = ""
      this.$refs.ruleForm.resetFields();
      this.$emit("closeaccesskeydialog");
    },

    // 获取Amazon区域列表
    getAwsRegions() {
      callCenter
        .getAwsRegions()
        .then((res) => {
          this.areaList = res.data;
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped lang="scss">
.transfer-client-owner {
  ::v-deep .el-form-item__label {
    height: 30px;
  }
  ::v-deep .el-form-item {
    margin-bottom: 10px;
  }
}
</style>
