<template>
  <div class="batchadd-num">
    <!-- 批量添加号码 -->
    <el-dialog
      :visible="batchaddnumDialog"
      top="10%"
      :title="$t('label.callcenter.batch.add.number')"
      width="55%"
      :close-on-click-modal="false"
      :before-close="closeDialog"
    >
      <el-table :data="tableData" style="width: 100%" max-height="300" border>
        <!-- 用户 -->
        <el-table-column :label="$t('label.tabpage.userz')">
          <template slot-scope="scope">
            <span class="requiredIdentification">*</span>
            <!-- 请选择用户 -->
            <el-input
              :placeholder="$t('message.please.choose.user')"
              v-model="scope.row.name"
              size="mini"
              @focus="remoteSearchOwner(scope.$index)"
            >
              <i slot="prefix" style="align: right">
                <span class="el-icon-search"></span>
              </i>
            </el-input>
          </template>
        </el-table-column>
        <!-- 坐席工号 -->
        <el-table-column :label="$t('label.callcenter.Seat.number')">
          <template slot-scope="scope">
            <span class="requiredIdentification">*</span>
            <!-- 请选择坐席工号 -->
            <el-select
              v-model="scope.row.bindCno"
              :placeholder="$t('label.callcenter.please.select .seatID')"
              size="mini"
              class="seat-num"
              @focus="getClientList(scope.$index)"
            >
              <el-option
                v-for="item in jobnumberoptions"
                :key="item.id"
                :label="item.bindcno"
                :value="item.bindcno"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <!-- <el-table-column label="电话类型">
          <template slot-scope="scope">
            <span class="requiredIdentification">*</span>
            <el-select
              v-model="scope.row.phoneType"
              placeholder="请选择电话类型"
              size="mini"
              @change="changedisabled(scope.row.phoneType, scope.$index)"
            >
              <el-option
                v-for="item in teloptions"
                :key="item"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="电话号码">
          <template slot-scope="scope">
            <span class="requiredIdentification" v-show="!scope.row.disabled"
              >*</span
            >
            <el-input
              placeholder="请输入电话号码"
              v-model="scope.row.phone"
              size="mini"
              :disabled="scope.row.disabled"
            >
            </el-input>
          </template>
        </el-table-column> -->
        <!-- 操作 -->
        <el-table-column :label="$t('label.emailsync.form.action')" width="120">
          <template slot-scope="scope">
            <el-button
              @click.native.prevent="deleteRow(scope.$index, tableData)"
              type="text"
              size="small"
            >
              <!-- 删除    -->
              {{ $t("component.chatter.button.delete") }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 增加一行 -->
      <el-button type="primary" @click="add" class="add-one">{{
        $t("label.batchadd.newbatchpage.button.add")
      }}</el-button>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button size="mini" @click="saveDialog" type="primary">
          <!-- 保存 -->
          {{ $t("label.save") }}
        </el-button>
      </span>
    </el-dialog>
    <!-- 查找/查找多选 -->
    <!-- 搜索 -->
    <el-dialog
      :title="$t('label.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      style="height: 91%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
    >
      <search-table
        ref="searchTable"
        @changeSelect="changeSelectOwner"
        :userstr="userstr"
      />
    </el-dialog>
  </div>
</template>
<script type="text/ecmascript-6">
import * as callCenter from "./api.js";
import SearchTable from "@/views/systemSettings/components/components/searchTable.vue";
export default {
  components: {
    SearchTable,
  },
  props: {
    batchaddnumDialog: {
      type: Boolean,
      default: false,
    },
    tabledate: {
      type: Array,
      default: ()=>[],
    },
  },
  data() {
    return {
      tabledateProp: this.tabledate,
      tableData: [
        {
          bindUserId: "",
          bindCno: "",
          name: "",
        },
        {
          bindUserId: "",
          bindCno: "",
          name: "",
        },
        {
          bindUserId: "",
          bindCno: "",
          name: "",
        },
        {
          bindUserId: "",
          bindCno: "",
          name: "",
        },
      ],
      // teloptions: [
      //   {
      //     value: "1",
      //     label: "普通电话",
      //   },
      //   {
      //     value: "2",
      //     label: "软电话",
      //   },

      // ],
      jobnumberoptions: [],
      fieldId: "",
      relevantObjId: "user",
      relevantPrefix: "",
      showSearchTable: false,
      checked: false,
      index: null,
      userstr: [],
      clientarr: [],
    };
  },
  methods: {
    //查询坐席下拉列表
    getClientList() {
      this.clientarr = [];
      for (let i in this.tableData) {
        if (this.tableData[i].bindCno !== "") {
          this.clientarr.push(this.tableData[i].bindCno);
        }
      }
      this.tabledateProp.forEach((item) => {
        this.clientarr.push(item.bindcno);
      });
      let params = {
        filters: this.clientarr.join(","),
      };
      callCenter
        .getClientList(params)
        .then((res) => {
          this.jobnumberoptions = res.data;
        })
        .catch(() => {});
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    saveDialog() {
      let findIndex = 0;
      findIndex = this.tableData.findIndex((res) => {
          if (
            res["bindUserId"] == "" ||
            res["bindCno"] == ""
            // ((res["bindUserId"] == "" ||
            //   res["bindCno"] == "" ||
            //   res["phoneType"] == "") &&
            //   res["disabled"] == true) ||
            // ((res["bindUserId"] == "" ||
            //   res["bindCno"] == "" ||
            //   res["phoneType"] == "" ||
            //   res["phone"] == "") &&
            //   res["disabled"] == false)
          ) {
            return true;
          }
      });
      if (findIndex >= 0) {
        // "存在必填项未填写，请检查"
        this.$message.error(
          this.$i18n.t("label.batchadd.newbatchpage.save.emptytext")
        );
      } else {
        callCenter
          .bindClientUsers(JSON.stringify(this.tableData))
          .then((res) => {
            
            if (res.returnCode == "400") {
              this.$message.error(
                `坐席号为${res.data.join(",")}的电话号码已存在`
              );
              return true;
            }
            this.closeDialog();
            this.$emit("getBindTable");
          })
          .catch(() => {});
      }
    },
    closeDialog() {
      this.tableData = [
        {
          bindUserId: "",
          bindCno: "",
          name: "",
        },
        {
          bindUserId: "",
          bindCno: "",
          name: "",
        },
        {
          bindUserId: "",
          bindCno: "",
          name: "",
        },
        {
          bindUserId: "",
          bindCno: "",
          name: "",
        },
      ];
      this.$emit('update:batchaddnumDialog',false)
    },
    add() {
      if (this.tableData.length < 10) {
        this.tableData.push({
          bindUserId: "",
          bindCno: "",
          name: "",
        });
      } else {
        // "一次最多添加10条数据"
        this.$message.warning(this.$i18n.t("label.callcenter.addup.10.pieces"));
      }
    },
    // 查找所有人
    remoteSearchOwner(index) {
      this.userstr = [];
      this.index = index;
      this.showSearchTable = true;
      for (let i in this.tableData) {
        if (this.tableData[i].bindUserId !== "") {
          this.userstr.push(this.tableData[i].bindUserId);
        }
      }
      this.tabledateProp.forEach((item) => {
        this.userstr.push(item.binduserid);
      });
      this.$nextTick(() => {
        this.$refs.searchTable.init();
      });
    },
    changeSelectOwner(row) {
      for (var i = 0; i < this.tableData.length; i++) {
        if (this.index == i) {
          this.tableData[i].bindUserId = row.id;
          this.tableData[i].name = row.name;
        }
      }
      this.showSearchTable = false;
    },
    changedisabled(type, index) {
      if (type == "2") {
        this.tableData[index].disabled = true;
      } else {
        this.tableData[index].disabled = false;
      }
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.requiredIdentification {
  color: red;
  position: absolute;
  left: 2px;
  top: 2px;
}
.add-one {
  margin-top: 16px;
}
.seat-num {
  width: 100%;
}
</style>