<template>
  <div class="transfer-client-owner">
    <el-dialog
      :visible="loginDialog"
      top="15%"
      :width="'420px'"
      :close-on-click-modal="false"
      :before-close="closeloginDialog"
    >
      <div slot="title" class="header-title">
        <div style="display: flex">
          <svg
            class="logoIcon"
            aria-hidden="true"
            slot="reference"
            style="width: 24px; height: 24px; margin-right: 8px"
          >
            <use href="#icon-trrtIcon"></use>
          </svg>
          <div>{{ $t("label.callcenter.Tianrun.RONGTONG") }}</div>
        </div>
      </div>
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        class="demo-dynamic"
        :rules="rules"
        label-position="left"
      >
        <el-form-item prop="accesskey">
          <label slot="label">{{ $t("label.callcenter.access.keyID") }}:</label>
          <el-input
            v-model="ruleForm.accesskey"
            type="password"
            :placeholder="$t('label.callcenter.enterprise.configuration')"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item prop="privateaccesskey">
          <label slot="label">{{ $t("label.callcenter.private.key") }}:</label>
          <el-input
            v-model="ruleForm.privateaccesskey"
            type="password"
            :placeholder="$t('label.callcenter.enterprise.configuration')"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item prop="companyCno">
          <label slot="label"
            >{{ $t("label.callcenter.enterprise.number") }}:</label
          >
          <el-input
            v-model="ruleForm.companyCno"
            :placeholder="$t('front-kefu-moudle-trindex-copy-num')"
            size="mini"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- 暂时注释 -->
        <a
          style="
            margin-left: 10px;
            font-size: 14px;
            color: #006dcc;
            cursor: pointer;
            float: left;
          "
          @click="freeTry"
          >{{ $t("front-kefu-moudle-v1-use-free") }}</a
        >
        <span>
          <el-button @click="closeloginDialog()" size="mini">
            <!-- 取消 -->
            {{ $t("label.cancel") }}
          </el-button>
          <el-button size="mini" @click="submitForm('ruleForm')" type="primary">
            {{ $t("label.emailsync.form.signin") }}
          </el-button></span
        >
      </span>
    </el-dialog>
    <!-- 查找/查找多选 -->
    <!-- 搜索 -->
    <el-dialog
      :title="$t('label.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      style="height: 91%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
    >
      <search-table ref="searchTable" @changeSelect="changeSelectOwner" />
    </el-dialog>
    <!-- 试用弹窗 -->
    <el-dialog
      :visible="tryOnDialog"
      top="15%"
      :width="'420px'"
      :title="$t('front-kefu-moudle-bind-userinfo')"
      :close-on-click-modal="false"
      :before-close="closetryOnDialog"
    >
      <div class="editDialog">
        <el-table
          :data="editData"
          border
          style="width: 100%"
          :highlight-current-row="false"
        >
          <el-table-column :label="$t('front-kefu-moudle-callcenter-user')">
            <template slot-scope="scope">
              <span>{{ scope.row.cno }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('front-kefu-moudle-service-agent')">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.username"
                :placeholder="$t('message.please.choose.user')"
                @focus="remoteSearchOwner"
                :validate-event="false"
              >
                <i slot="prefix" class="el-input__icon el-icon-search"></i
              ></el-input>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <span>
          <el-button @click="closetryOnDialog">
            <!-- 取消 -->
            {{ $t("label.cancel") }}
          </el-button>
          <el-button @click="confirmtryOnDialog" type="primary">
            {{ $t("label.tabpage.ok") }}
          </el-button></span
        >
      </span>
    </el-dialog>
    <!-- 试用到期 -->
    <el-dialog
      :visible.sync="expireDialog"
      custom-class="expireDialog"
      top="15%"
      :width="'564px'"
      :close-on-click-modal="false"
      :before-close="closeexpireDialog"
    >
      <div class="expire">
        <div class="title">
          <div>{{ $t("front-kefu-moudle-free-hasexpire") }}。</div>
          <div>{{ $t("front-kefu-moudle-or-this") }}</div>
        </div>
        <div class="content">
          <span> {{ $t("front-kefu-moudle-buytr-tip") }}</span>
        </div>
        <div class="btn">
          <el-button type="primary" @click="confirmBuy">{{
            $t("front-kefu-moudle-yes-wantbuy")
          }}</el-button>
        </div>
        <div class="nobuy" @click="closeexpireDialog">
          <span>{{ $t("front-kefu-moudle-think-again") }}</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script type="text/ecmascript-6">
import * as callCenterApi from "./api.js"; // 呼叫中心接口
import SearchTable from "@/views/systemSettings/components/components/searchTable.vue";
export default {
  props: {
    loginDialog: {
      type: Boolean,
      default: false,
    },
    tryOnDialog: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SearchTable,
  },
  data() {
    return {
      ruleForm: {
        accesskey: "",
        privateaccesskey: "",
        companyCno: "",
      },
      rules: {
        accesskey: [
          {
            required: true,
            message: this.$i18n.t("label.callcenter.enterprise.configuration"),
            trigger: "blur",
          },
        ],
        privateaccesskey: [
          {
            required: true,
            message: this.$i18n.t("label.callcenter.enterprise.configuration"),
            trigger: "blur",
          },
        ],
        companyCno: [
          {
            required: true,
            message: this.$i18n.t("front-kefu-moudle-trindex-copy-num"),
            trigger: "blur",
          },
        ],
      },
      editData: [
        {
          userid: "",
          username: "",
          cno: "",
        },
      ],
      // 搜索用户会话框
      showSearchTable: false,
      expireDialog: false,
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            intergrateType: "tianrun",
          };
          callCenterApi.getCallCenterInfo(params).then((resResult) => {
            if (resResult.result) {
              let params = {
                appId: this.ruleForm.accesskey,
                appSecret: this.ruleForm.privateaccesskey,
                intergrateType: "tianrun",
                isEnable: "1",
                callMissed: "2",
                callConnected: "1",
                ishidden: "0",
                isOnTrial: "0",
                enterpriseNo: this.ruleForm.companyCno,
              };
              callCenterApi
                .saveCCSetting(params)
                .then((res) => {
                  if (res.result && res.returnCode == "200") {
                    this.$message.success(
                      this.$i18n.t(
                        "label.callcenter.successfully.logged.account"
                      )
                    );
                    this.$emit("chineseSubmit");
                    this.$refs[formName].resetFields();
                  } else if (res.result && res.returnCode == "300") {
                    this.$message({
                      message: this.$i18n.t(
                        "label.callcenter.Incorrect.data.filling"
                      ),
                      type: "error",
                    });
                  } else if (res.result && res.returnCode == "400") {
                    this.$message({
                      message: this.$i18n.t("front-kefu-moudle-trkey-nopower"),
                      type: "error",
                    });
                  }
                })
                .catch(() => {});
            } else {
              
              return false;
            }
          });
        }
      });
    },
    closeloginDialog() {
      this.$refs.ruleForm.resetFields();
      this.$emit("closelogindialog");
    },

    // 免费试用
    freeTry() {
      let params = {
        intergrateType: "tianrun",
      };
      callCenterApi.getCallCenterInfo(params).then((resInfo) => {
        if (resInfo.result) {
          if (resInfo.data.isOnTrialState == "0") {
            let params = {};
            callCenterApi.getOnTrial(params).then((res) => {
              this.editData[0].cno = res.data;
              this.closeloginDialog();
              this.$emit("opentryOnDialog");
              this.editData[0].userid = localStorage.getItem("userId");
              this.editData[0].username = localStorage.getItem("username");
            });
          } else if (resInfo.data.isOnTrialState == "1") {
            let params = {
              intergrateType: "tianrun",
              isEnable: "1",
              isOnTrial: "1",
            };
            callCenterApi.saveCCSetting(params).then((res) => {
              if (res.result) {
                callCenterApi.getBindList().then((resList) => {
                  if (resList.result) {
                    let bindParams = {
                      bindUserId: resList.data[0].binduserid,
                      bindCno: resList.data[0].bindcno,
                      id: resList.data[0].id,
                      isOnTrial: "1",
                    };
                    callCenterApi
                      .updateClientUser(bindParams)
                      .then((bindRes) => {
                        if (bindRes.result) {
                          this.$emit("freeTrySuccess");
                        }
                      });
                  }
                });
              }
            });
          } else if (resInfo.data.isOnTrialState == "2") {
            this.expireDialog = true;
          }
        }
      });
    },

    // 确认试用
    confirmtryOnDialog() {
      if (this.editData[0].cno == "") {
        this.$message({
          message: this.$i18n.t("vue_label_email_contact_adminstrator"),
          type: "error",
        });
      } else {
        let params = {
          intergrateType: "tianrun",
          isEnable: "1",
          isOnTrial: "1",
        };
        callCenterApi.saveCCSetting(params).then((res) => {
          if (res.result) {
            callCenterApi.getBindList().then((resList) => {
              if (resList.result) {
                if (resList.data.length == 0) {
                  let bindParams = {
                    bindUserId: this.editData[0].userid,
                    bindCno: this.editData[0].cno,
                  };
                  callCenterApi.bindClientUser(bindParams).then((bindRes) => {
                    if (bindRes.result) {
                      this.$emit("freeTrySuccess");
                    }
                  });
                } else {
                  let bindParams = {
                    bindUserId: this.editData[0].userid,
                    bindCno: this.editData[0].cno,
                    id: resList.data[0].id,
                    isOnTrial: "1",
                  };
                  callCenterApi.updateClientUser(bindParams).then((bindRes) => {
                    if (bindRes.result) {
                      this.$emit("freeTrySuccess");
                    }
                  });
                }
              }
            });
          }
        });
      }
    },

    // 关闭弹框
    closetryOnDialog() {
      this.$emit("closetryOnDialog");
      this.$emit("openloginDialog");
    },

    confirmBuy() {
      callCenterApi.sendTianRunEmail().then((res) => {
        if (res.result && res.returnCode == "200") {
          this.$message({
            message: this.$i18n.t("front-kefu-moudle-sendemail-success"),
            type: "success",
          });
          this.expireDialog = false;
        } else if (res.result && res.returnCode == "300") {
          this.$alert(
            this.$i18n.t("front-kefu-moudle-buytr-send-email"),
            this.$i18n.t("label.emailtocloudcc.tip"),
            {
              confirmButtonText: this.$i18n.t("label.tabpage.ok"),
            }
          );
        } else {
          this.$message({
            message: this.$i18n.t("label.emailtocloudcc.redmessage5"),
            type: "error",
          });
        }
      });
    },

    closeexpireDialog() {
      this.expireDialog = false;
    },

    // 查找用户
    remoteSearchOwner() {
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.init();
      });
    },

    changeSelectOwner(row) {
      this.editData[0].userid = row.id;
      this.editData[0].username = row.name;
      this.showSearchTable = false;
    },
  },
};
</script>
<style scoped lang="scss">
.transfer-client-owner {
  ::v-deep .el-form-item__label {
    height: 30px;
  }
  ::v-deep .el-form-item {
    margin-bottom: 10px;
  }
}
.editDialog {
  ::v-deep .el-table td {
    padding: 0;
  }
  ::v-deep .el-table--border td {
    border-right: 1px solid #ebeef5 !important;
  }
  ::v-deep .el-table .cell {
    padding: 3px;
  }
  ::v-deep .el-table tbody tr:hover > td {
    background-color: #ffffff !important;
  }
  ::v-deep .el-table thead {
    color: #333333;
  }
  ::v-deep .el-table--border th,
  .el-table__fixed-right-patch {
    background-color: #f0f0f0;
    border-radius: 3px 3px 0px 0px;
    border: 1px solid #dcdcdc;
  }
  ::v-deep .el-table--border td {
    border-right: none;
  }
  .atags a {
    color: #006dcc;
  }
  ::v-deep .el-form-item {
    margin-bottom: 10px;
  }
}
::v-deep .expireDialog {
  .el-dialog__header {
    border-bottom: none;
    height: 57px;
  }
  .el-dialog__body {
    padding: 0;
  }
  .expire {
    background: linear-gradient(180deg, #f4fcff 0%, #ffffff 100%);
    height: 337px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      width: 210px;
      font-size: 20px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      text-align: center;
      margin-top: 35px;
    }
    .content {
      width: 462px;
      font-size: 17px;
      font-family: MicrosoftYaHei;
      color: #666666;
      margin-top: 45px;
      text-align: center;
    }
    .btn {
      margin-top: 45px;
    }
    .nobuy {
      margin-top: 17px;
      cursor: pointer;
    }
  }
}
</style>
