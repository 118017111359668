<template>
  <div class="call-center-container">
    <div class="top">
      <div class="topLeft">
        <svg class="logoIcon" style="" aria-hidden="true" slot="reference">
          <use :href="ZHcountry ? '#icon-trrtIcon' : '#icon-awsIcon'"></use>
        </svg>
        <div style="margin-left: 16px">
          <div class="TRtitle">
            <div v-show="ZHcountry">
              <span>{{ $t("front-kefu-moudle-v1-trythjzx-intelligent") }}</span>
              <span v-show="freeTrying" class="freeContent">{{
                $t("front-kefu-moudle-v1-mfsysy-time")
              }}</span>
              <span v-show="freeTrying" class="freeBox">{{ freeDays }}</span>
            </div>
            <div v-show="!ZHcountry">
              <div>{{ $t("front-kefu-moudle-v1-awshjzx-intelligent") }}</div>
            </div>
          </div>
          <div class="TRcontent">
            <span v-show="ZHcountry">{{
              $t("front-kefu-moudle-v1-jctrrt-call-serve")
            }}</span>
            <span v-show="!ZHcountry">{{
              $t("front-kefu-moudle-v1-jcaws-call-serve")
            }}</span>
          </div>
        </div>
      </div>
      <!-- 呼叫中心开关 -->
      <div class="topRight">
        <div class="switchRight">
          <el-switch
            v-if="!freeTrying"
            v-model="switchValue"
            :width="45"
            :inactive-text="
              !switchValue ? $t('label.nousing') : $t('label.inusing')
            "
            inactive-color="#999999"
            active-color="#006DCC"
            @change="switchClick"
          >
          </el-switch>
          <el-switch
            v-else
            v-model="switchValue"
            :width="45"
            :inactive-text="
              !switchValue
                ? $t('label.nousing')
                : $t('front-kefu-moudle-open-free')
            "
            inactive-color="#999999"
            active-color="#006DCC"
            @change="switchClick"
          >
          </el-switch>
        </div>
      </div>
    </div>
    <div class="main">
      <el-tabs
        v-model="activeName"
        @tab-click="tabhandleClick"
        ref="tabs"
        v-if="!freeTrying"
      >
        <el-tab-pane label="" name="first" :disabled="true">
          <div class="defaTabs">
            <span>{{ $t("front-kefu-moudle-v1-opencall-please") }}</span>
          </div>
        </el-tab-pane>
        <el-tab-pane
          :label="$t('label.emailsync.form.seversetting')"
          :disabled="tabsBool"
          name="second"
        >
          <!-- 设置首页 -->
          <div v-if="SZshow" class="SZPosi">
            <p class="SZtitle">
              {{ $t("front-kefu-moudle-v1-jtrrt-connect") }}
            </p>
            <p class="SZtips">
              {{ $t("front-kefu-moudle-v1-trrt-tip") }}
            </p>
            <p>
              <el-button type="primary" @click="relationClick" size="medium">{{
                $t("front-kefu-moudle-v1-connect-phone")
              }}</el-button>
              <el-button type="primary" @click="batchDialog" size="medium">{{
                $t("label.callcenter.batch.add.number")
              }}</el-button>
            </p>
            <div class="tablePosi">
              <div class="tableTitle">
                <div class="userinfo">
                  <span>{{ $t("front-kefu-moudle-v1-user-info") }}</span>
                </div>
              </div>
              <!-- 用户信息 -->
              <div class="tablemain">
                <el-table
                  :data="tableData"
                  border
                  style="width: 100%"
                  height="288"
                  v-loading="tableLoading"
                >
                  <el-table-column
                    :label="$t('label.department.operation')"
                    min-width="204"
                  >
                    <template slot-scope="scope">
                      <div class="atags">
                        <a
                          href="javascript:void(0)"
                          @click="handleEdit(scope.$index, scope.row)"
                        >
                          <span>{{
                            $t("pagecreator.page.button.edit")
                          }}</span></a
                        >
                        |
                        <a
                          href="javascript:void(0)"
                          @click="handleDelete(scope.$index, scope.row)"
                          ><span>{{
                            $t("component.chatter.button.delete")
                          }}</span></a
                        >
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('label.department.username')"
                    min-width="204"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.row.bindusername }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('label.callcenter.Seat.number')"
                    min-width="204"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.row.bindcno }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('front-kefu-moudle-v1-phone-type')"
                    min-width="204"
                  >
                    <template slot-scope="scope">
                      <span
                        v-if="
                          scope.row.phonetype == '1' ||
                          scope.row.phonetype == '2'
                        "
                        >{{ $t("front-kefu-moudle- normal-phone") }}</span
                      >
                      <span v-else-if="scope.row.phonetype == '3'">{{
                        $t("front-kefu-moudle-IP-phone")
                      }}</span>
                      <span v-else-if="scope.row.phonetype == '4'">{{
                        $t("front-kefu-moudle-soft-phone")
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('label.textmessage.vonage.phone.number')"
                    min-width="204"
                  >
                    <template slot-scope="scope">
                      <span
                        ><span>{{ scope.row.phone }} </span></span
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <!-- 编辑用户信息 -->
                <el-dialog
                  :title="$t('front-kefu-moudle-edit-userinfo')"
                  :visible.sync="userEditVisible"
                  width="23%"
                  top="30vh"
                  customClass="customWidth"
                >
                  <div class="editDialog">
                    <el-table
                      :data="editData"
                      border
                      style="width: 100%"
                      :highlight-current-row="false"
                    >
                      <el-table-column
                        :label="$t('label.emailsync.status.user')"
                      >
                        <template slot-scope="scope">
                          <span>{{ scope.row.user }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        :label="$t('label.callcenter.Seat.number')"
                      >
                        <template slot-scope="scope">
                          <el-select
                            v-model="scope.row.bindCno"
                            :placeholder="
                              $t('label.callcenter.please.select .seatID')
                            "
                            @change="JobnumChange"
                          >
                            <el-option
                              v-for="item in jobNumoption"
                              :key="item.id"
                              :label="item.bindcno"
                              :value="item.bindcno"
                            >
                            </el-option>
                          </el-select>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="userEditVisibleClick">{{
                      $t("label.tabpage.cancel")
                    }}</el-button>
                    <el-button type="primary" @click="saveTable">{{
                      $t("label.tabpage.ok")
                    }}</el-button>
                  </span>
                </el-dialog>
                <el-dialog
                  :title="$t('label.popup.infoz')"
                  :visible.sync="userDeleteVisible"
                  width="30%"
                  top="30vh"
                >
                  <div>
                    <span class="deletePop">{{
                      $t("front-kefu-moudle-confirm-disable-phone-user", [
                        userAction,
                      ])
                    }}</span>
                  </div>
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="userDeleteVisible = false">{{
                      $t("label.tabpage.cancel")
                    }}</el-button>
                    <el-button type="primary" @click="deleteConfirm">{{
                      $t("label.tabpage.ok")
                    }}</el-button>
                  </span>
                </el-dialog>
              </div>
            </div>
          </div>
          <!-- 关联电话号码 -->
          <div v-else>
            <div class="SZconnect">
              <el-form
                :inline="true"
                :model="SZForm"
                :rules="SZrules"
                ref="SZForm"
                label-width="77px"
                class="demo-ruleForm"
              >
                <el-form-item
                  :label="$t('label.tabpage.userz') + ' :'"
                  prop="user"
                >
                  <el-input
                    v-model="SZForm.user"
                    :placeholder="$t('message.please.choose.user')"
                    @focus="remoteSearchOwner"
                    :validate-event="false"
                  >
                    <i slot="prefix" class="el-input__icon el-icon-search"></i
                  ></el-input>
                </el-form-item>
                <el-form-item
                  :label="$t('label.callcenter.Seat.number') + ' :'"
                  prop="bindCno"
                >
                  <el-select
                    v-model="SZForm.bindCno"
                    :placeholder="$t('label.callcenter.please.select .seatID')"
                    @change="JobnumChange"
                  >
                    <el-option
                      v-for="item in jobNumoption"
                      :key="item.id"
                      :label="item.bindcno"
                      :value="item.bindcno"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <div class="btnPosi">
                <el-button
                  type="primary"
                  size="medium"
                  @click="SZsubmitForm('SZForm')"
                  >{{ $t("label.chatter.save") }}</el-button
                >
                <el-button @click="SZresetForm('SZForm')" size="medium">{{
                  $t("label.tabpage.cancel")
                }}</el-button>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <!-- 秘钥设置 -->
        <el-tab-pane
          :label="$t('label.linkedin.setting.appid')"
          :disabled="tabsBool"
          name="four"
        >
          <div class="SZPosi">
            <div class="tablePosi">
              <div class="tableTitle">
                <div class="userinfo">
                  <span>{{ $t("front-kefu-moudle-v1-user-info") }}</span>
                </div>
                <div class="savetable">
                  <el-button
                    @click="keysaveTable"
                    v-show="keysaveBtnshow"
                    size="medium"
                    >{{ $t("label.chatter.save") }}</el-button
                  >
                </div>
              </div>
              <div class="tablemain">
                <el-table :data="keytableData" border style="width: 100%">
                  <el-table-column
                    :label="$t('label.department.operation')"
                    min-width="340"
                  >
                    <template slot-scope="scope">
                      <div class="atags">
                        <a
                          href="javascript:void(0)"
                          @click="keyhandleEdit(scope.$index, scope.row)"
                        >
                          <span>{{ $t("label.department.user.edit") }}</span></a
                        >
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('label.callcenter.access.keyID')"
                    min-width="340"
                  >
                    <template slot-scope="scope">
                      <div v-if="scope.row.editBool">
                        <el-input
                          ref="focusInput"
                          type="password"
                          v-model="scope.row.keyID"
                        ></el-input>
                      </div>
                      <div v-else>
                        <span>
                          <span v-show="!idshowPass">
                            {{ scope.row.keyID | formatPass }}</span
                          >
                          <span v-show="idshowPass">{{ scope.row.keyID }}</span>
                          <svg
                            class="icon eyeIcon"
                            aria-hidden="true"
                            @click="eyeiconClick('id')"
                          >
                            <use
                              :href="
                                idshowPass ? '#icon-show' : '#icon-show_blue'
                              "
                            ></use>
                          </svg>
                        </span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('label.callcenter.private.key')"
                    min-width="340"
                  >
                    <template slot-scope="scope">
                      <div v-if="scope.row.editBool">
                        <el-input
                          type="password"
                          v-model="scope.row.privateKey"
                        ></el-input>
                      </div>
                      <div v-else>
                        <span>
                          <span v-show="!privateshowPass">
                            {{ scope.row.privateKey | formatPass }}</span
                          >
                          <span v-show="privateshowPass">{{
                            scope.row.privateKey
                          }}</span>
                          <svg
                            class="icon eyeIcon"
                            aria-hidden="true"
                            @click="eyeiconClick('private')"
                          >
                            <use
                              :href="
                                privateshowPass
                                  ? '#icon-show'
                                  : '#icon-show_blue'
                              "
                            ></use>
                          </svg>
                        </span>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <!-- CRM设置 -->
        <el-tab-pane
          :label="$t('front-kefu-moudle-CRM-set')"
          :disabled="tabsBool"
          name="third"
        >
          <div class="CRMposi">
            <el-form
              ref="CRMform"
              :model="CRMform"
              label-width="194px"
              label-position="left"
            >
              <el-form-item
                :label="$t('front-kefu-moudle-which-missphone-case')"
                prop="phoneCase"
              >
                <el-select
                  v-model="CRMform.phoneCase"
                  :placeholder="$t('label.tabpage.pleaseSelectz')"
                >
                  <el-option
                    v-for="item in optionsCreate"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                :label="$t('front-kefu-moudle-which-getphone-case')"
                prop="phoneRules"
              >
                <el-select
                  v-model="CRMform.phoneRules"
                  :placeholder="$t('label.tabpage.pleaseSelectz')"
                >
                  <el-option
                    v-for="item in optionsRules"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                :label="$t('front-kefu-moudle-all-hidden-phone')"
                prop="hideBool"
              >
                <el-checkbox v-model="CRMform.hideBool"></el-checkbox>
              </el-form-item>
            </el-form>
            <div class="btnPosi">
              <el-button
                type="primary"
                @click="CRMsubmitForm('CRMform')"
                size="medium"
                :disabled="disableSave"
                >{{ $t("label.chatter.save") }}</el-button
              >
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <!-- 试用 -->
      <el-tabs v-model="freeActiveName" v-else>
        <el-tab-pane label="" name="second" disabled="true">
          <div class="defaTabs">
            <span>{{ $t("front-kefu-moudle-v1-opencall-please") }}</span>
          </div>
        </el-tab-pane>
        <el-tab-pane
          :label="$t('label.emailsync.form.seversetting')"
          name="first"
        >
          <div class="SZPosi">
            <p class="SZtitle">
              {{ $t("front-kefu-moudle-connect-free-account") }}
            </p>
            <div class="tablePosi">
              <div class="tableTitle">
                <div class="userinfo">
                  <span>{{ $t("front-kefu-moudle-v1-user-info") }}</span>
                </div>
              </div>
              <!-- 用户信息 -->
              <div class="tablemain">
                <el-table
                  :data="tableData"
                  border
                  style="width: 100%"
                  height="288"
                  v-loading="tableLoading"
                >
                  <el-table-column
                    :label="$t('label.department.operation')"
                    min-width="204"
                  >
                    <template slot-scope="scope">
                      <div class="atags">
                        <a
                          href="javascript:void(0)"
                          @click="freehandleEdit(scope.$index, scope.row)"
                        >
                          <span>{{ $t("label.department.user.edit") }}</span></a
                        >
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('label.department.username')"
                    min-width="204"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.row.bindusername }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('front-kefu-moudle-free-account')"
                    min-width="204"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.row.bindcno }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 编辑试用 -->
    <el-dialog
      :visible.sync="freetryOnDialog"
      top="15%"
      :width="'420px'"
      :title="$t('front-kefu-moudle-bind-userinfo')"
      :close-on-click-modal="false"
      :before-close="closefreetryOnDialog"
    >
      <div class="editDialog">
        <el-table
          :data="freeeditData"
          border
          style="width: 100%"
          :highlight-current-row="false"
        >
          <el-table-column :label="$t('front-kefu-moudle-callcenter-user')">
            <template slot-scope="scope">
              <span>{{ scope.row.cno }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('front-kefu-moudle-service-agent')">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.username"
                :placeholder="$t('message.please.choose.user')"
                @focus="remoteSearchOwner"
                :validate-event="false"
              >
                <i slot="prefix" class="el-input__icon el-icon-search"></i
              ></el-input>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <span>
          <el-button @click="closefreetryOnDialog">
            <!-- 取消 -->
            {{ $t("label.cancel") }}
          </el-button>
          <el-button @click="confirmfreetryOnDialog" type="primary">
            {{ $t("label.tabpage.ok") }}
          </el-button></span
        >
      </span>
    </el-dialog>
    <!-- 引用组件 -->
    <batchaddnumber
      ref="batchadd"
      :batchaddnum-dialog.sync="batchaddnumDialog"
      :tabledate="tableData"
      @getBindTable="getBindTable"
    ></batchaddnumber>
    <chineselogin
      :login-dialog="loginDialog"
      :tryOnDialog="tryOnDialog"
      @openloginDialog="openloginDialog"
      @closelogindialog="closelogindialog"
      @chineseSubmit="chineseSubmit"
      @freeTrySuccess="freeTrySuccess"
      @opentryOnDialog="opentryOnDialog"
      @closetryOnDialog="closetryOnDialog"
    ></chineselogin>
    <englishlogin
      :englogin-dialog="engloginDialog"
      @closeenglogindialog="closeenglogindialog"
    ></englishlogin>
    <disabled
      :disable-dialog="disableDialog"
      @closedisabledialog="closedisabledialog"
      @confirmdisableDialog="confirmdisableDialog"
    ></disabled>
    <accesskeylogin
      ref="accesskey"
      :accesskey-dialog="accesskeyDialog"
      @closeaccesskeydialog="closeaccesskeydialog"
      @accesskeySubmit="accesskeySubmit"
    ></accesskeylogin>
    <!-- 查找/查找多选 -->
    <!-- 搜索 -->
    <el-dialog
      :title="$t('label.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      style="height: 91%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
    >
      <search-table
        ref="searchTable"
        @changeSelect="changeSelectOwner"
        :userstr="userstr"
      />
    </el-dialog>
  </div>
</template>

<script>
import batchaddnumber from "./batchaddnumber.vue";
import chineselogin from "./chineselogin.vue";
import englishlogin from "./englishlogin.vue";
import disabled from "./disabled.vue";
import accesskeylogin from "./accesskeylogin.vue";
import * as callCenterApi from "./api.js";
import SearchTable from "@/views/systemSettings/components/components/searchTable.vue";
export default {
  name: "call-center",
  components: {
    batchaddnumber,
    chineselogin,
    englishlogin,
    disabled,
    accesskeylogin,
    SearchTable,
  },
  watch: {
    CRMform: {
      handler: function () {
        if (
          this.CRMform.phoneCase == this.currentCRMform.phoneCase &&
          this.CRMform.phoneRules == this.currentCRMform.phoneRules &&
          this.CRMform.hideBool == this.currentCRMform.hideBool
        ) {
          this.disableSave = true;
        } else {
          this.disableSave = false;
        }
      },
      deep: true,
    },
  },
  data() {
    var validateUser = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$i18n.t("message.please.choose.user")));
      } else {
        callback();
      }
    };
    var validateBindCno = (rule, value, callback) => {
      if (value === "") {
        callback(
          new Error(this.$i18n.t("label.callcenter.please.select .seatID"))
        );
      } else {
        callback();
      }
    };
    return {
      countryCodeCall:
        this.$store.state.userInfoObj.language == "zh" ? "CN" : "",
      disableSave: false,
      // 试用状态
      freeTrying: false,
      tryOnDialog: false,
      freetryOnDialog: false,
      freeActiveName: "first",
      freeeditData: [
        {
          id: "",
          userid: "",
          username: "",
          cno: "",
        },
      ],
      freeDays: 0,
      // 表格loading
      tableLoading: true,
      // 国家判断
      ZHcountry: true,
      // 开关
      switchValue: false,
      // 开关控制tabs是否禁用
      tabsBool: true,
      // 显示默认tabs
      activeName: "first",
      // 展示设置页
      SZshow: true,
      // 编辑弹窗
      userEditVisible: false,
      // 删除弹窗
      userDeleteVisible: false,
      // 搜索用户会话框
      showSearchTable: false,
      // 密钥表格的保存按钮显示
      keysaveBtnshow: false,
      // 默认小眼睛
      idshowPass: false,
      // 默认删除提示用户名
      userAction: "",
      // 第三方类型（tianrun或者aws）
      intergrateType: "",
      // 暂存keyid
      transientKeyid: "",
      // 暂存私钥
      transientPrivateKey: "",
      // 密码隐藏和展示
      privateshowPass: false,
      // 已选用户
      userstr: [],
      // 电话号码输入框禁用
      phoneNumDisable: false,
      // 编辑用户电话错误框
      errorInput: false,
      batchaddnumDialog: false, //批量添加号码
      loginDialog: false, //中文登录
      engloginDialog: false, //海外登录
      disableDialog: false, //禁用
      accesskeyDialog: false,
      // 关联电话表单
      SZForm: {
        userid: "",
        user: "",
        bindCno: "",
        phoneType: "",
        phone: "",
      },
      SZrules: {
        user: [{ validator: validateUser }],
        bindCno: [{ validator: validateBindCno }],
      },
      // 坐席电话下拉
      jobNumoption: [],
      // 默认crm选项
      CRMform: {
        phoneCase: "",
        phoneRules: "",
        hideBool: false,
      },
      currentCRMform: {
        phoneCase: "",
        phoneRules: "",
        hideBool: false,
      },
      // 为哪些漏接的电话创建个案
      optionsCreate: [
        {
          label: this.$i18n.t("front-kefu-moudle-no-create"),
          value: "0",
        },
        {
          label: this.$i18n.t("front-kefu-moudle-all-missphone"),
          value: "1",
        },
        {
          label: this.$i18n.t("front-kefu-moudle-callin-missphone"),
          value: "2",
        },
        {
          label: this.$i18n.t("front-kefu-moudle-callout-missphone"),
          value: "3",
        },
      ],
      // 接通电话后创建个案的规则
      optionsRules: [
        {
          label: this.$i18n.t("front-kefu-moudle-no-create"),
          value: "0",
        },
        {
          label: this.$i18n.t("front-kefu-moudle-auto-callphone"),
          value: "1",
        },
        {
          label: this.$i18n.t("front-kefu-moudle-needhand-createcase"),
          value: "2",
        },
      ],
      // 绑定列表
      tableData: [],
      // 编辑列表项表单
      editData: [
        {
          id: "",
          userid: "",
          user: "",
          bindCno: "",
          phoneType: "",
          phone: "",
        },
      ],
      // 密钥
      keytableData: [
        {
          keyID: "",
          privateKey: "",
        },
      ],
    };
  },
  filters: {
    formatPass(val) {
      if (val) {
        let passVal = "";
        let passVallength = val.length;
        while (passVallength > 0) {
          passVal += "*";
          passVallength--;
        }
        return passVal;
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    // 初始化
    init() {
      if (this.countryCodeCall == "CN") {
        this.ZHcountry = true;
        this.intergrateType = "tianrun";
      } else {
        this.ZHcountry = false;
        this.intergrateType = "aws";
        this.$nextTick(() => {
          this.$refs.tabs.$children[0].$refs.tabs[1].style.display = "none";
        });
      }
      let params = {
        intergrateType: this.intergrateType,
      };
      // 获取呼叫中心设置
      callCenterApi.getCallCenterInfo(params).then((res) => {
        if (res.result  && res.data) {
          // 正式账号
          if (res.data.isEnable === "1") {
            if (res.data.isOnTrial == "0") {
              this.switchValue = true;
              if (res.data.intergrateType === "tianrun") {
                this.chineseSubmit();
              } else if (res.data.intergrateType === "aws") {
                this.accesskeySubmit();
              }
            } // 试用账号
            else if (res.data.isOnTrial == "1") {
              this.switchValue = true;
              this.freeDays = res.data.surplusDay;
              this.freeTrySuccess();
            }
          } else if (res.data.isEnable === "0") {
            this.switchValue = false;
          }
        }
      });
    },

    // 开关切换
    switchClick() {
      if (this.switchValue) {
        // this.switchValue = false;
        if (this.ZHcountry) {
          this.loginDialog = true;
        } else {
          this.accesskeyDialog = true;
          this.$nextTick(() => {
            this.$refs.accesskey.getAwsRegions();
          });
        }
      } else {
        this.disableDialog = true;
        // this.switchValue = true;
      }
    },

    // 试用成功
    freeTrySuccess() {
      this.freeTrying = true;
      this.switchValue = true;
      this.loginDialog = false;
      this.tryOnDialog = false;
      this.freeActiveName = "first";
      this.tabsBool = false;
      this.tableLoading = true;
      let params = {
        intergrateType: this.intergrateType,
      };
      // 获取呼叫中心设置
      callCenterApi.getCallCenterInfo(params).then((res) => {
        if(res.data){
          this.freeDays = res.data.surplusDay;
        }
      });
      this.getBindTable();
    },

    // 中文登录成功
    chineseSubmit() {
      this.freeTrying = false;
      this.switchValue = true;
      this.loginDialog = false;
      this.activeName = "second";
      this.tabsBool = false;
      this.tableLoading = true;
      this.getBindTable();
      this.getKeyCrm();
    },

    //  国内登录框关闭
    closelogindialog() {
      this.loginDialog = false;
      this.switchValue = false;
      this.activeName = "first";
    },

    opentryOnDialog() {
      this.tryOnDialog = true;
    },
    closetryOnDialog() {
      this.tryOnDialog = false;
    },

    openloginDialog() {
      this.loginDialog = true;
    },

    freehandleEdit(index, row) {
      this.freeeditData[0].id = row.id;
      this.freeeditData[0].userid = row.binduserid;
      this.freeeditData[0].username = row.bindusername;
      this.freeeditData[0].cno = row.bindcno;
      this.freetryOnDialog = true;
    },

    closefreetryOnDialog() {
      this.freetryOnDialog = false;
    },

    confirmfreetryOnDialog() {
      let params = {
        bindUserId: this.freeeditData[0].userid,
        bindCno: this.freeeditData[0].cno,
        id: this.freeeditData[0].id,
        isOnTrial: "1",
      };
      callCenterApi.updateClientUser(params).then((res) => {
        if (res.result) {
          this.$message({
            message: this.$i18n.t("vue_label_notice_modify_success"),
            type: "success",
          });
          this.closefreetryOnDialog();
          this.getBindTable();
        }
      });
    },

    // 获取绑定列表
    getBindTable() {
      callCenterApi.getBindList().then((res) => {
        if (res.result) {
          this.tableData = res.data;
          this.tableLoading = false;
        }
      });
    },

    // 获取密钥和crm设置
    getKeyCrm() {
      let params = {
        intergrateType: this.intergrateType,
      };
      callCenterApi.getCallCenterInfo(params).then((res) => {
        if (res.result) {
          this.keytableData[0].keyID = res.data.appId;
          this.keytableData[0].privateKey = res.data.appSecret;
          this.transientKeyid = res.data.appId;
          this.transientPrivateKey = res.data.appSecret;
          this.CRMform.phoneCase = res.data.callMissed;
          this.CRMform.phoneRules = res.data.callConnected;
          if (res.data.ishidden == "1") {
            this.CRMform.hideBool = true;
          } else if (res.data.ishidden == "0") {
            this.CRMform.hideBool = false;
          }
          // 存储当前crm设置
          this.currentCRMform.phoneCase = res.data.callMissed;
          this.currentCRMform.phoneRules = res.data.callConnected;
          this.currentCRMform.hideBool = this.CRMform.hideBool;
        }
      });

      this.keytableData.forEach((item) => {
        this.$set(item, "editBool", false);
      });
    },

    // tab切换
    tabhandleClick() {
      if (this.activeName === "second" && !this.SZshow) {
        this.SZresetForm("SZForm");
      } else if (this.activeName === "four") {
        if (
          this.keytableData[0].keyID !== this.transientKeyid &&
          this.keytableData[0].privateKey !== this.transientPrivateKey
        ) {
          let params = {
            appId: this.keytableData[0].keyID,
            appSecret: this.keytableData[0].privateKey,
            intergrateType: this.intergrateType,
          };
          callCenterApi.getCallCenterInfo(params).then((res) => {
            if (res.result) {
              this.keytableData[0].keyID = res.data.appId;
              this.keytableData[0].privateKey = res.data.appSecret;
              this.transientKeyid = res.data.appId;
              this.transientPrivateKey = res.data.appSecret;
            }
          });
        } else {
          this.keytableData[0].keyID = this.transientKeyid;
          this.keytableData[0].privateKey = this.transientPrivateKey;
        }
        this.keytableData.forEach((item) => {
          item.editBool = false;
        });
        this.keysaveBtnshow = false;
        this.idshowPass = false;
        this.privateshowPass = false;
      }
    },

    // 关联电话号码
    relationClick() {
      this.SZshow = false;
      this.selectjobNum();
    },

    // 查找用户
    remoteSearchOwner() {
      this.userstr = [];
      this.showSearchTable = true;
      this.tableData.forEach((item) => {
        if (item.binduserid !== "") {
          this.userstr.push(item.binduserid);
        }
      });
      this.$nextTick(() => {
        this.$refs.searchTable.init();
      });
    },

    changeSelectOwner(row) {
      if (this.freeTrying) {
        this.freeeditData[0].userid = row.id;
        this.freeeditData[0].username = row.name;
      } else {
        this.SZForm.userid = row.id;
        this.SZForm.user = row.name;
      }
      this.showSearchTable = false;
    },

    // 查询坐席下拉列表
    selectjobNum() {
      let tableArr = [];
      this.tableData.forEach((item) => {
        tableArr.push(item.bindcno);
      });
      let params = {
        filters: tableArr.join(","),
      };
      callCenterApi.getClientList(params).then((res) => {
        if (res.returnCode === "200") {
          this.jobNumoption = res.data;
        } else if (res.returnCode === "300") {
          this.$message({
            message: this.$i18n.t("label.query.error"),
            type: "error",
          });
          this.jobNumoption = [];
        }
      });
    },

    // 查找坐席工号
    JobnumChange(value) {
      this.editData[0].bindCno = value;
      this.selectjobNum();
    },

    // 电话类型切换
    
    JobtypeChange() {
      if (this.SZForm.phoneType === "2") {
        this.SZForm.phone = null;
        this.phoneNumDisable = true;
      } else if (this.SZForm.phoneType === "1") {
        this.SZForm.phone = "";
        this.phoneNumDisable = false;
      }
    },

    // 设置首页表格操作列 编辑
    handleEdit(index, row) {
      this.userEditVisible = true;
      this.editData[0].user = row.bindusername;
      this.editData[0].bindCno = row.bindcno;
      this.editData[0].id = row.id;
      this.editData[0].userid = row.binduserid;
      this.editData[0].phoneType = row.phonetype;
      this.editData[0].phone = row.phone;
      this.selectjobNum();
    },

    // 编辑表格切换电话类型
    
    selectPhoneTypeChange() {
      if (this.editData[0].phoneType === "2") {
        this.editData[0].phone = null;
      } else if (this.editData[0].phoneType === "1") {
        this.editData[0].phone = "";
      }
    },

    // 保存编辑
    saveTable() {
      this.errorInput = false;
      if (this.editData[0].phoneType === "2") {
        this.editData[0].phone = null;
      }
      let params = {
        bindUserId: this.editData[0].userid,
        bindCno: this.editData[0].bindCno,
        id: this.editData[0].id,
        isOnTrial: "0",
        phoneType: this.editData[0].phoneType,
        phone: this.editData[0].phone,
      };
      callCenterApi.updateClientUser(params).then((res) => {
        if (res.result && res.returnCode === "200") {
          this.$message({
            message: this.$i18n.t("vue_label_notice_modify_success"),
            type: "success",
          });
          this.userEditVisible = false;
          this.tableLoading = true;
          this.getBindTable();
        } else if (res.result && res.returnCode === "300") {
          this.$message({
            message: this.$i18n.t("label.custombutton.queryerror"),
            type: "error",
          });
        }
      });
    },

    // 取消编辑
    userEditVisibleClick() {
      this.errorInput = false;
      this.userEditVisible = false;
    },

    // 设置首页表格操作列 删除
    handleDelete(index, row) {
      this.userDeleteVisible = true;
      this.userAction = row.bindusername;
      this.editData[0].id = row.id;
      this.editData[0].bindCno = row.bindcno;
    },

    // 删除确认
    deleteConfirm() {
      let params = {
        id: this.editData[0].id,
        bindCno: this.editData[0].bindCno,
      };
      callCenterApi.deleteClientUser(params).then((res) => {
        if (res.result) {
          this.$message({
            message: this.$i18n.t("label.tabpage.delsuccessz"),
            type: "success",
          });
          this.userDeleteVisible = false;
          this.tableLoading = true;
          this.getBindTable();
        }
      });
    },

    // 绑定坐席电话设置保存
    SZsubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            bindUserId: this.SZForm.userid,
            bindCno: this.SZForm.bindCno,
            phoneType: this.SZForm.phoneType,
            phone: this.SZForm.phone,
          };
          callCenterApi.bindClientUser(params).then((res) => {
            if (res.result && res.returnCode === "200") {
              this.$message({
                message: this.$i18n.t("label.search.saveok"),
                type: "success",
              });
              this.$refs[formName].resetFields();
              this.phoneNumDisable = false;
              this.SZshow = true;
              this.tableLoading = true;
              this.getBindTable();
            } else if (res.result && res.returnCode === "300") {
              this.$message({
                message: this.$i18n.t("chatter.save.f"),
                type: "error",
              });
            }
          });
        } else {
          return false;
        }
      });
    },

    // 设置取消
    SZresetForm(formName) {
      this.$refs[formName].resetFields();
      this.phoneNumDisable = false;
      this.SZshow = true;
    },

    // 密钥设置表格编辑
    keyhandleEdit(index) {
      this.keytableData[index].editBool = true;
      this.keysaveBtnshow = true;
      this.$nextTick(() => {
        this.$refs.focusInput.focus();
      });
    },

    // 密钥设置表格保存
    keysaveTable() {
      let params = {
        intergrateType: this.intergrateType,
      };
      callCenterApi.getCallCenterInfo(params).then((res) => {
        if (res.result) {
          let params = {
            appId: this.keytableData[0].keyID,
            appSecret: this.keytableData[0].privateKey,
            intergrateType: this.intergrateType,
            isEnable: res.data.isEnable,
            callMissed: this.CRMform.phoneCase,
            callConnected: this.CRMform.phoneRules,
            ishidden: this.CRMform.hideBool ? "1" : "0",
            enterpriseNo: res.data.enterpriseNo,
            isOnTrial: "0",
          };
          if (this.intergrateType == "aws") {
            params.region = res.data.region;
          }
          callCenterApi.saveCCSetting(params).then((res) => {
            if (res.result && res.returnCode === "200") {
              this.$message({
                message: this.$i18n.t("label.search.saveok"),
                type: "success",
              });
              this.keytableData.forEach((item) => {
                item.editBool = false;
              });
              this.keysaveBtnshow = false;
              this.idshowPass = false;
              this.privateshowPass = false;
              this.transientKeyid = this.keytableData[0].keyID;
              this.transientPrivateKey = this.keytableData[0].privateKey;
            } else if (res.result && res.returnCode === "300") {
              this.$message({
                message: this.$i18n.t(
                  "label.callcenter.Incorrect.data.filling"
                ),
                type: "error",
              });
            } else if (res.result && res.returnCode == "400") {
              this.$message({
                message: this.$i18n.t("front-kefu-moudle-trkey-nopower"),
                type: "error",
              });
            }
          });
        }
      });
    },

    // 密码展示切换
    eyeiconClick(type) {
      if (type === "id") {
        this.idshowPass = !this.idshowPass;
      }
      if (type === "private") {
        this.privateshowPass = !this.privateshowPass;
      }
    },

    // CRM设置保存
    CRMsubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            intergrateType: this.intergrateType,
          };
          callCenterApi.getCallCenterInfo(params).then((res) => {
            if (res.result) {
              let params = {
                appId: res.data.appId,
                appSecret: res.data.appSecret,
                intergrateType: this.intergrateType,
                isEnable: "1",
                callMissed: this.CRMform.phoneCase,
                callConnected: this.CRMform.phoneRules,
                ishidden: this.CRMform.hideBool ? "1" : "0",
                enterpriseNo: res.data.enterpriseNo,
                isOnTrial: "0",
              };
              if (this.intergrateType == "aws") {
                params.region = res.data.region;
              }
              callCenterApi.saveCCSetting(params).then((res) => {
                if (res.result && res.returnCode === "200") {
                  this.$message({
                    message: this.$i18n.t("label.search.saveok"),
                    type: "success",
                  });
                  this.SZshow = true;
                  this.disableSave = true;
                  this.getKeyCrm();
                } else if (res.result && res.returnCode === "300") {
                  this.$message({
                    message: this.$i18n.t(
                      "label.callcenter.Incorrect.data.filling"
                    ),
                    type: "error",
                  });
                } else if (res.result && res.returnCode === "400") {
                  this.$message({
                    message: this.$i18n.t("front-kefu-moudle-trkey-nopower"),
                    type: "error",
                  });
                }
              });
            }
          });
        } else {
          return false;
        }
      });
    },

    // 批量编辑弹窗开启
    batchDialog() {
      this.batchaddnumDialog = true;
    },


    // 关闭amazon connect框
    closeenglogindialog() {
      this.engloginDialog = false;
    },

    // 关闭或取消禁用框
    closedisabledialog() {
      this.disableDialog = false;
      this.switchValue = true;
      this.tabsBool = false;
    },

    // 确定禁用呼叫中心弹窗
    confirmdisableDialog() {
      let params = {
        intergrateType: this.intergrateType,
      };
      callCenterApi.getCallCenterInfo(params).then((res) => {
        if (res.result) {
          let params = {
            appId: res.data.appId,
            appSecret: res.data.appSecret,
            intergrateType: this.intergrateType,
            isEnable: "0",
            callMissed: "",
            callConnected: "",
            ishidden: this.CRMform.hideBool ? "1" : "0",
            enterpriseNo: res.data.enterpriseNo,
          };
          if (this.intergrateType == "aws") {
            params.region = res.data.region;
          }
          if (this.freeTrying) {
            params.isOnTrial = "1";
          } else {
            params.isOnTrial = "0";
          }
          callCenterApi.saveCCSetting(params).then((res) => {
            if (res.result && res.returnCode === "200") {
              this.$message({
                message: this.$i18n.t("lable.Disabled.successfully"),
                type: "success",
              });
              this.disableDialog = false;
              this.switchValue = false;
              this.freeTrying = false;
              this.tabsBool = true;
              this.activeName = "first";
            } else if (res.result && res.returnCode === "300") {
              this.$message({
                message: this.$i18n.t(
                  "label.callcenter.Incorrect.data.filling"
                ),
                type: "error",
              });
            } else if (res.result && res.returnCode === "400") {
              this.$message({
                message: this.$i18n.t("front-kefu-moudle-trkey-nopower"),
                type: "error",
              });
            }
          });
        }
      });
    },

    // aws登录成功
    accesskeySubmit() {
      this.switchValue = false;
      this.switchValue = true;
      this.accesskeyDialog = false;
      this.activeName = "four";
      this.tabsBool = false;
      this.getKeyCrm();
    },

    // 关闭或取消aws登录框
    closeaccesskeydialog() {
      this.accesskeyDialog = false;
      this.switchValue = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .customWidth {
  min-width: 473px;
}
.top {
  height: 88px;
  border-bottom: 1px #dedcda solid;
  margin: 0 -20px;
  display: flex;
  justify-content: space-between;
  flex-flow: row;
  padding: 16px;
  .topLeft {
    // flex: 1;
    display: flex;
    .logoIcon {
      width: 56px;
      height: 56px;
      // float: left;
      margin-left: 16px;
    }
    .TRtitle {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      height: 38px;
      display: flex;
      align-items: center;
      span {
        display: inline-block;
      }
      .freeContent {
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-left: 10px;
      }
      .freeBox {
        min-width: 25px;
        height: 25px;
        background: #006dcc;
        border-radius: 7px 7px 7px 7px;
        opacity: 1;
        text-align: center;
        margin-left: 5px;
        color: #ffffff;
      }
    }
    .TRcontent {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
  }
  .topRight {
    width: 160px;
    float: right;
    line-height: 56px;
    .switchRight {
      float: right;
      margin-right: 16px;
      ::v-deep .el-switch__label.is-active {
        font-weight: bold;
        color: #999999;
      }
      ::v-deep .el-switch__label {
        font-weight: bold;
        color: #006dcc;
      }
    }
    span {
      font-size: 16px;
      font-weight: 500;
      color: #999999;
    }
  }
}
.main {
  margin: 0 -20px;
  ::v-deep .el-tab-pane {
    padding: 0 11px;
  }
  // tab禁用
  ::v-deep .el-tabs__item.is-disabled {
    color: #080707;
  }
  .defaTabs {
    font-size: 20px;
    color: #080707;
    display: flex;
    justify-content: center;
    margin-top: 15%;
  }
  .SZPosi {
    margin-left: 28px;
    .SZtitle {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      margin-bottom: 16px;
    }
    .SZtips {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #666666;
      margin-bottom: 16px;
    }
    .tablePosi {
      margin-top: 20px;
      margin-bottom: 20px;
      margin-right: 25px;
      border: 1px #dcdcdc solid;
      border-radius: 3px;
      background-color: #f8f8f8;
      padding: 20px 10px 10px 10px;
      .tableTitle {
        .userinfo {
          float: left;
          margin-bottom: 20px;
          font-size: 16px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #333333;
        }
        .savetable {
          display: flex;
          justify-content: center;
        }
      }
      .tablemain {
        ::v-deep .el-table thead {
          color: #333333;
        }
        ::v-deep .el-table--border th,
        .el-table__fixed-right-patch {
          background-color: #f0f0f0;
          // border-radius: 3px 3px 0px 0px;
          border: 1px solid #dcdcdc;
          color: #333333;
        }
        ::v-deep .el-table--border td {
          border-right: none;
          color: #333333;
        }
        .atags a {
          color: #006dcc;
        }
        ::v-deep .el-form-item {
          margin-bottom: 10px;
        }
        ::v-deep .el-table th {
          padding: 8px 0;
        }
        ::v-deep .el-table td {
          padding: 8px 0;
        }
      }
    }
  }
  .SZconnect {
    ::v-deep .el-form--inline .el-form-item__content {
      width: 180px;
    }
    ::v-deep .el-form-item__label {
      font-size: 14px;
      color: #333333;
    }
    ::v-deep .el-input {
      font-size: 12px;
    }
    .btnPosi {
      margin: 10px 0 0 30px;
    }
  }

  .CRMposi {
    margin-left: 23px;
    ::v-deep .el-select {
      width: 210px;
    }
    ::v-deep .el-form-item {
      margin-bottom: 20px;
    }
    ::v-deep .el-form-item__label {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
    }
  }
}
.eyeIcon {
  float: right;
}
.editDialog {
  ::v-deep .el-table td {
    padding: 0;
  }
  ::v-deep .el-table--border td {
    border-right: 1px solid #ebeef5 !important;
  }
  ::v-deep .el-table .cell {
    padding: 3px;
  }
  ::v-deep .el-table tbody tr:hover > td {
    background-color: #ffffff !important;
  }
  ::v-deep .el-table thead {
    color: #333333;
  }
  ::v-deep .el-table--border th,
  .el-table__fixed-right-patch {
    background-color: #f0f0f0;
    // border-radius: 3px 3px 0px 0px;
    border: 1px solid #dcdcdc;
  }
  ::v-deep .el-table--border td {
    border-right: none;
  }
  .atags a {
    color: #006dcc;
  }
  ::v-deep .el-form-item {
    margin-bottom: 10px;
  }
}
.deletePop {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #080707;
}
.footBottom {
  position: fixed;
  bottom: 0;
  left: 10px;
  margin-right: 20px;
  width: -webkit-fill-available;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #ffffff;
  box-shadow: 0px -3px 7px 0px rgba(0, 0, 0, 0.22);
  .phoneIcon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.redBorder {
  border-radius: 4px;
  ::v-deep .el-input .el-input__inner {
    border-color: red !important;
  }
}
</style>
