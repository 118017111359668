<template>
  <div class="transfer-client-owner">
    <!-- 提示 -->
    <el-dialog
      :visible="disableDialog"
      :title="$t('UG.ST.001')"
      top="15%"
      width="25%"
      :close-on-click-modal="false"
      :before-close="closedisableDialog"
    >
      <div style="font-size: 16px; font-weight: bold; word-break: break-word">
        <!-- 确定要禁用当前呼叫中心吗？ -->
        {{ $t("label.callcenter.sure.disable.center") }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closedisableDialog" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button @click="confirmdisableDialog" type="primary" size="mini">
          <!-- 确定 -->
          {{ $t("label.tabpage.ok") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script type="text/ecmascript-6">
export default {
  props: {
    disableDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    closedisableDialog() {
      this.$emit("closedisabledialog");
    },
    confirmdisableDialog() {
      this.$emit("confirmdisableDialog");
    },
  },
};
</script>
<style scoped lang="scss">
</style>
