<template>
  <div class="transfer-client-owner">
    <el-dialog
      :visible="engloginDialog"
      top="15%"
      width="30%"
      append-to-body
      :close-on-click-modal="false"
      :before-close="closeloginDialog"
    >
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        class="demo-dynamic"
        :rules="rules"
      >
        <h4 style="display: flex; justify-content: center; font-size: 16px">
          <!-- Amazon Connect连接 -->
          {{ $t("label.callcenter.link.Amazon.connect") }}
        </h4>
        <!-- 实例名称 -->
        <el-form-item prop="name" :label="$t('label.callcenter.Instance.name')">
          <!-- <el-input
            v-model="ruleForm.name"
            placeholder="请输入实例名称"
            size="mini"
          ></el-input> -->
          <!-- 请选择 -->
          <el-select
            v-model="ruleForm.name"
            :placeholder="$t('label.tabpage.pleaseSelectz')"
          >
            <el-option
              v-for="(item, index) in InstanceAliasList"
              :key="index"
              :label="item.instanceAlias"
              :value="item.instanceAlias"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- 注意：确保您已经创建了Amazon Connect的实例 -->
      <div>{{ $t("label.callcenter.makesure.Amazon.connect") }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="submitForm('ruleForm')" type="primary">
          <!-- 登录到Amazon Connect -->
          {{ $t("label.callcenter.login.Amazon.connect") }}
        </el-button>
        <!-- 稍后登录 -->
        <el-button @click="closeloginDialog" size="mini">
          {{ $t("label.callcenter.login.later") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script type="text/ecmascript-6">
import * as callCenterApi from "./api.js"; // 呼叫中心接口
export default {
  props: {
    engloginDialog: {
      type: Boolean,
      default: false,
    },
    loginName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      InstanceAliasList: [],
      ruleForm: {
        name: "",
      },
      rules: {
        // "请输入用户名"
        name: [
          {
            required: true,
            message: this.$i18n.t("label.weixin.input.usernames"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 跳转aws登录页面
          // window.open(`https://cloudcccallcenter.my.connect.aws/ccp-v2/`)
          // window.open(`https://${this.ruleForm.name}.my.connect.aws/ccp-v2/`)
          // this.$emit("englishSubmit");
          this.saveInstance();
        } else {
          
          return false;
        }
      });
    },
    closeloginDialog() {
      this.$refs.ruleForm.resetFields();
      this.$emit("closeenglogindialog");
    },
    // 获取Amazon实例名
    getInstanceAlias() {
      callCenterApi.getInstanceAlias().then((res) => {
        if (res.result) {
          if (res.data.length == 0) {
            this.$message.warning("该区域尚未创建实例，请重新选择");
          } else {
            this.$emit("openenglogindialog");
            this.InstanceAliasList = res.data;
            res.data.map((item) => {
              if (item.theLast) {
                this.ruleForm.name = item.instanceAlias;
              }
            });
          }
        }
      });
    },
    // 保存amzon实例名
    saveInstance() {
      let params = {
        instanceAlias: this.ruleForm.name,
      };
      callCenterApi.saveInstance(params).then((res) => {
        if (res.result) {
          if (this.loginName == this.ruleForm.name) {
            this.$parent.closeenglogindialog();
          }
          this.$nextTick(() => {
            this.$emit("inittel", this.ruleForm.name);
          });
          this.closeloginDialog();
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-dialog__header {
  padding: 16px 14px 36px 36px;
}
</style>
